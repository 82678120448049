import React, { Component } from 'react'
import Chart from "chart.js";
let myLineChart;

class LineGraph extends Component {
    chartRef = React.createRef();

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.buildChart();
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        const { data, average, labels } = this.props;

        if (typeof myLineChart !== "undefined") myLineChart.destroy();

        myLineChart = new Chart(myChartRef, {
            type: "scatter",
            data: {
                //Bring in data
                labels: labels,
                datasets: [
                    {
                        label: "Sales",
                        data: data,
                        fill: false,
                        pointRadius: 0,
                        borderWidth: 3,
                        borderColor: "#6610f2",
                        showLine: true
                    }
                ]
            },
            options: {
              showTooltips: true,
              legend: {
                display: false
              },
              scales: {
               xAxes: [{
                 ticks: {
                   display: false
                 },
                 gridLines: {
                   display: false
                 }
               }],
               yAxes: [{
                 ticks: {
                   display: false
                 },
                gridLines: {
                  display: false,
                }
               }]
             },
            }
        });

    }

    render() {

        return (
            <div>
                <canvas
                    id="myChart"
                    ref={this.chartRef}
                />
            </div>
        )
    }
}

export default LineGraph;
