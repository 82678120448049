import React from 'react';

function OfferAlert(props) {
  return(
    <div className="rounded-md bg-green-50 p-4 mb-4">
      <div className="flex">
      <div class="flex-shrink-0">
        <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
        </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-md leading-5 font-medium text-green-800">
            {props.heading}
          </h3>
          <div className="mt-2 text-md leading-5 text-green-700">
            <p>
              {props.body}
            </p>
          </div>
        </div>
      </div>
    </div>

  )
}

export default OfferAlert;
