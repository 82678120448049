import React, { useState, useEffect } from "react";
import countapi from 'countapi-js';
import Layout from "../components/layout";
import SEO from "../components/seo";
import LineGraph from "../components/lineGraph";
import OfferAlert from "../components/offerAlert";
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
let nfObject = new Intl.NumberFormat('en-US')

function Token ({data, location}) {

  const id = data.contentfulToken.coingeckoId
  const slug = location.pathname.replace('/', '')

  const [ prices, setPrices ] = useState(0)
  const [ stats, setStats ] = useState(0)

  useEffect(() => {
    console.log(slug)
    countapi.hit('tokensack.com', slug).then((result) => { console.log(result) });

    const strippedPrices = []
    fetch('https://api.coingecko.com/api/v3/coins/'+id+'/market_chart?vs_currency=usd&days=30')
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        resultData.prices.map(entry => {
          strippedPrices.push({'x': entry[0], 'y': entry[1]});
        })
        setPrices(strippedPrices);
      }) // set data for the number of stars

    const strippedStats = {}
    fetch('https://api.coingecko.com/api/v3/coins/'+id+'?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false')
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        strippedStats['price'] = resultData.market_data.current_price.usd
        strippedStats['priceChange'] = resultData.market_data.price_change_percentage_24h
        strippedStats['marketCap'] = resultData.market_data.market_cap.usd
        setStats(strippedStats)
      })

  }, [])


  return(


    <Layout>
    <SEO
      title={data.contentfulToken.tokenName+" Price & Information"}
    />


    <div className="relative bg-white">
      <div className="h-56 sm:h-72 lg:absolute lg:left-0 lg:h-full lg:w-1/2 lg:py-16 sm:mb-10 md:mb-40">
        <LineGraph data={prices} />
      </div>
      <div className="relative max-w-screen-xl mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
        <div className="max-w-2xl mx-auto lg:max-w-none lg:mr-0 lg:ml-auto lg:w-1/2 lg:pl-10">
          <div>
            <div className="flex items-center justify-center h-12 w-12 rounded-md text-white">
              {/*<svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>*/}
              <img className="flex-shrink-0 mx-auto rounded-full" src={data.contentfulToken.icon.fixed.src}></img>
            </div>
          </div>
          <h2 className="mt-6 text-3xl leading-9 font-bold text-gray-900 sm:text-4xl sm:leading-10">
            {data.contentfulToken.tokenName}
          </h2>
          <p className="mt-6 text-lg leading-7 text-gray-500">
            {documentToReactComponents(data.contentfulToken.tokenDescription.json)}
          </p>
          <div className="mt-8 overflow-hidden">
            <dl className="-mx-8 -mt-8 flex flex-wrap">
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base leading-6 font-medium text-gray-500">
                  Price
                </dt>
                <dd className="order-1 text-2xl leading-8 font-bold text-indigo-600 sm:text-3xl sm:leading-9">
                  ${stats.price}
                </dd>
              </div>
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base leading-6 font-medium text-gray-500">
                  24 Hr Price Change
                </dt>
                <dd className="order-1 text-2xl leading-8 font-bold text-indigo-600 sm:text-3xl sm:leading-9">
                  {stats.priceChange}%
                </dd>
              </div>
              <div className="flex flex-col px-8 pt-8">
                <dt className="order-2 text-base leading-6 font-medium text-gray-500">
                  Market Cap
                </dt>
                <dd className="order-1 text-2xl leading-8 font-bold text-indigo-600 sm:text-3xl sm:leading-9">
                  ${nfObject.format(stats.marketCap)}
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-8">
          <OfferAlert heading={data.contentfulToken.offerHeading} body={data.contentfulToken.offerBody} />
  <div className="inline-flex rounded-md shadow">
    <a href={data.contentfulToken.tradeUrl}  target="_blank" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
      Trade Token
      <svg className="-mr-1 ml-3 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
        <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
        <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
      </svg>
    </a>
  </div>
</div>
        </div>
      </div>
    </div>


    </Layout>
  )
}

export default Token;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulToken(slug: { eq: $slug }) {
      tokenName
      tokenDescription {
        json
      }
      symbol
      icon {
       fixed {
          width
          height
          src
          srcSet
        }
      }
      coingeckoId
      tradeUrl
      offerHeading
      offerBody
    }
  }
`;
